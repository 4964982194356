import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  BlogPostBySlugQuery,
  // eslint-disable-next-line import/extensions,import/no-unresolved
} from '../../types/graphql-types';
import ContentfulRichText from './contentfulRichText';
import { Header1, Header3 } from './headers';
import Layout from './layout';
import SEO from './seo';
import LinkComponent from './linkComponent';
import { Facebook, Twitter } from '../assets/vectors/socialIcons';

type Props = {
  pageContext: {
    header: string;
    slug: string;
  };
  data: BlogPostBySlugQuery;
};

const BlogPost: React.FC<Props> = ({ pageContext, data }: Props) => {
  const author = data?.contentfulBlogPost?.author;
  const authorPhoto = author?.headshot;

  let publishDate = data?.contentfulBlogPost?.createdAt;
  if (data?.contentfulBlogPost?.published) {
    publishDate = data?.contentfulBlogPost?.published;
  }

  const photoDesktop = data?.contentfulBlogPost?.photoDesktop;
  const photoMobile = data?.contentfulBlogPost?.photoMobile;

  return (
    <Layout pathname={`/blog/${pageContext.slug}`}>
      <SEO
        title={pageContext.header}
        shareImage={
          photoDesktop?.gatsbyImageData?.images?.fallback?.src
            ? `https:${photoDesktop.gatsbyImageData.images.fallback.src}`
            : undefined
        }
      />
      <div className="container mx-auto md:flex pt-12">
        <div className="w-1/4 pt-2 pr-10 hidden md:flex">
          {author && (
            <div className="flex">
              {authorPhoto && (
                <div
                  className="rounded-full overflow-hidden"
                  style={{ height: 80, width: 80 }}
                >
                  <LinkComponent link={`/author/${author.slug}`}>
                    <GatsbyImage
                      alt={author.name || ''}
                      image={authorPhoto.gatsbyImageData}
                    />
                  </LinkComponent>
                </div>
              )}
              <div>
                <LinkComponent link={`/author/${author.slug}`}>
                  <Header3 className="pb-1 pl-4 uppercase text-black hover:text-usm-dark-red">
                    {author.name}
                  </Header3>
                </LinkComponent>
                <LinkComponent link={`/author/${author.slug}`}>
                  <div
                    className="pl-4 text-gray-400"
                    style={{ fontSize: '0.65rem' }}
                  >
                    {publishDate}
                  </div>
                </LinkComponent>
                <div className="flex pl-4 mt-2">
                  <LinkComponent
                    link={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      `https://uhurusolidarity.org/blog/${pageContext.slug}`
                    )}`}
                    className="mr-3"
                  >
                    <Facebook />
                  </LinkComponent>
                  <LinkComponent
                    link={`https://www.twitter.com/share?url=${encodeURIComponent(
                      `https://uhurusolidarity.org/blog/${pageContext.slug}`
                    )}`}
                    className="mr-3"
                  >
                    <Twitter />
                  </LinkComponent>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="md:flex md:flex-1">
          <div className="md:max-w-screen-sm">
            {photoMobile && (
              <div className="md:hidden my-5">
                <GatsbyImage
                  alt="TODO: add alt tag"
                  image={photoMobile.gatsbyImageData}
                />
              </div>
            )}
            <Header1
              tag="h1"
              className="mb-4 mx-5 md:mx-0"
              color="black"
              mobileColorsFlipped
            >
              {pageContext.header}
            </Header1>
            {author && author?.slug !== 'uhuru-solidarity-movement' && (
              <b className="text-white inline-block md:hidden mx-5">
                by {author?.name}
              </b>
            )}
            {photoDesktop && (
              <div className="hidden md:block flex max-w-screen-sm">
                <GatsbyImage
                  alt="TODO: add alt tag"
                  image={photoDesktop.gatsbyImageData}
                />
              </div>
            )}
            <div className="blog-post-content text-base text-white md:text-black">
              <ContentfulRichText document={data?.contentfulBlogPost?.body} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      seoTitle
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            description
            gatsbyImageData(layout: FULL_WIDTH)
          }
          ... on ContentfulVideo {
            contentful_id
            __typename
            embedUrl
            thumbnail {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      photoDesktop: photo {
        gatsbyImageData(layout: FIXED, width: 640)
      }
      photoMobile: photo {
        gatsbyImageData(
          layout: CONSTRAINED
          aspectRatio: 1.75
          width: 700
          cropFocus: CENTER
          placeholder: BLURRED
        )
      }
      createdAt(formatString: "MMMM Do, YYYY")
      published(formatString: "MMMM Do, YYYY")
      author {
        name
        slug
        headshot {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    contentfulMenu(menuId: { eq: "main" }) {
      items {
        id
        text
        link
        appearsOnHomepage
        subMenu {
          id
          text
          link
          appearsOnHomepage
        }
      }
      ctaItem {
        text
        link
      }
      secondaryCtaItem {
        text
        link
      }
    }
  }
`;

export default BlogPost;
